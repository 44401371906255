<template>
    <div class="empty-list-placeholder" :style="elStyle">
        <el-image
            class="empty-img"
            :src="img"
            fit="fill"
        ></el-image>

        <div class="text">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import img from './assets/empty-list.png';
import img2 from './assets/empty-list2.png';
import img3 from './assets/empty-list3.png';

export default {
    props: {
        width: {
            type: [String,Number],
            default: '200px'
        },
        theme: {
            type: String,
            default: 'default',
        },
        // 上下间距
        margin: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            
        };
    },
    computed: {
        img() {
            var switchObj = {
                default: img3,
                cold: img2,
            };

            return switchObj[this.theme] || img3;
        },
        elStyle() {
            var style = {
                width: this.width,
            };

            if (this.margin) {
                style.marginTop = `${this.margin}px`;
                style.marginBottom = `${this.margin}px`;
            }

            return style;
        }
    }
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .empty-list-placeholder {
        width: 200px;
        margin: 100px auto;

        .empty-img {
            width: 100%;
        }

        .text{
            text-align: center;
            font-size: 16px;
            color: #bbb;
            font-weight: bold;
            margin-top: 24px;
            margin-left: -999px;
            margin-right: -999px;

            a{
                color: $dangerColor;
            }
        }
    }
</style>