<template>
    <div class="content">
        <empty-list
        width="360px"
        >
            404，找不到该页面
        </empty-list>
    </div>
</template>

<script>
import EmptyList from '@components/sys/empty-list';

export default {
    components: {
        EmptyList,
    },
}
</script>